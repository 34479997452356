/*// Container ratio
$ct-container-ratio: (1/1.618) !default;

// Text styles for labels
$ct-text-color: rgba(0, 0, 0, 0.4) !default;
$ct-text-size: 0.75rem !default;
$ct-text-align: flex-start !default;
$ct-text-justify: flex-start !default;
$ct-text-line-height: 1;

// Grid styles
$ct-grid-color: rgba(0, 0, 0, 0.2) !default;
$ct-grid-dasharray: 2px !default;
$ct-grid-width: 1px !default;
$ct-grid-background-fill: none !default;

// Line chart properties
$ct-line-width: 4px !default;
$ct-line-dasharray: false !default;

// Line chart point, can be either round or square
$ct-point-shape: round !default;
// Area fill transparency between 0 and 1
$ct-area-opacity: 0.1 !default;

// Bar chart bar width
$ct-bar-width: 10px !default;

// Donut width (If donut width is to big it can cause issues where the shape gets distorted)
$ct-donut-width: 60px !default;
*/


$ct-point-size: 7px;

$ct-series-names: (a, b, c, d, e, f, g, h, i, j, k, l, m, n, o);
$ct-series-colors: (
  $primary-500, //red
  $danger-500, //light red
  $warning-500, //yellow
  $info-500, //dark orange
  $fusion-500, //ash
  $success-500, //green
  $info-500, //blue
  $primary-900, //purple
  $danger-100, //lighter red
  $warning-200, //faded orange
  $danger-900, //faded yellow
  $fusion-300, //light gray
  $success-300, //light green
  $info-300, //light blue
  $primary-300 //light purple
);